import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { User } from '@models/User.model';
import { UserService } from '@shared/services/user.service';
import { SpinnerService } from '@fielddata.io/spinners';
import { SpinnerActionTypes } from '@fielddata.io/spinners';
import { HeaderUser } from '@fielddata.io/header';
import { Preference, PreferenceService } from '@fielddata.io/utils';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '@fielddata.io/auth';
import { BASIC_USER } from '@shared/commons/constants';

@Component({
  selector: 'fielddata-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {
  public user: HeaderUser;
  public isBasicUser: boolean;

  private preferenceSubscription: Subscription;

  constructor(
    private userService: UserService,
    private spinnerService: SpinnerService,
    private cd: ChangeDetectorRef,
    private cookie: CookieService,
    private translate: TranslateService,
    private preferenceService: PreferenceService,
    private permission: PermissionService
  ) {
    this.preferenceSubscription = this.preferenceService.getUserPreferencesSubject().subscribe((preference: Preference) => {
      // For new User the preference will be null
      if (preference) {
        this.translate.use(preference.language.shortName);
        this.cookie.set('language', preference.language.shortName, null, '/');
      }
    });
    this.spinnerService.pushSpinnerEvent('load-user', SpinnerActionTypes.ADD);
    this.userService.getUser().subscribe((user: User) => {
      // For new Sign in, value will be null
      if (user) {
        this.user = {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName
        } as HeaderUser;
        this.cd.markForCheck();
        this.spinnerService.pushSpinnerEvent('load-user', SpinnerActionTypes.REMOVE);
      }
      this.spinnerService.pushSpinnerEvent('load-permissions', SpinnerActionTypes.ADD);
      this.permission.getGlobalUserPermissions().subscribe((permissions: string[]) => {
        this.isBasicUser = permissions.includes(BASIC_USER);
        this.cd.markForCheck();
        this.spinnerService.pushSpinnerEvent('load-permissions', SpinnerActionTypes.REMOVE);
      }, () => {
        this.spinnerService.pushSpinnerEvent('load-permissions', SpinnerActionTypes.REMOVE);
      });
    });
  }

  public ngOnDestroy(): void {
    this.preferenceSubscription.unsubscribe();
  }
}
