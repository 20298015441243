import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppComponent } from './app.component';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { UserService } from '@shared/services/user.service';
import { AppRoutingModule } from '@app/app-routing.module';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { HttpParams } from '@angular/common/http';
import { DEFAULT_LANGUAGE } from '@shared/commons/constants';

export const appInitializerFactory = function(translate: TranslateService, injector: Injector, cookie: CookieService) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang(DEFAULT_LANGUAGE);
      const url = window.location.href;
      const httpParams = new HttpParams({fromString: url.split('?')[1]});
      if (httpParams.get('webbgmuser')) {
        localStorage.setItem('webbgm.user.token.new', httpParams.get('webbgmuser'));
        localStorage.setItem('endpoint', 'web-bgm');
      }
      if (httpParams.get('breportfd')) {
        localStorage.setItem('webbgm.user.token.new', httpParams.get('breportfd'));
        localStorage.setItem('endpoint', 'web-bgm-breport-fd');
      }
      const systemLanguage = window.navigator.language.split('-')[0];
      translate.use(systemLanguage).subscribe({
        error: () => {
          console.error('Could not load language, using English');
          cookie.set('language', 'en');
          translate.use(DEFAULT_LANGUAGE).subscribe(() => {
            resolve(null);
          });
        },
        complete: () => resolve(null)
      });
    });
  });
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule
  ],
  exports: [
    SharedModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [
        TranslateService,
        Injector,
        CookieService,
      ],
      multi: true
    },
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
