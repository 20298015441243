import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@fielddata.io/auth';
import { BreadcrumbModule } from '@fielddata.io/breadcrumb';
import { HeaderModule } from '@fielddata.io/header';

const routes: Routes = [
  {
    path: 'action',
    loadChildren: () => import('./action/action.module').then(m => m.ActionModule)
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'equipment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: 'configuration',
    canActivate: [AuthGuard],
    loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
  },
  {
    path: 'weather',
    canActivate: [AuthGuard],
    loadChildren: () => import('./weather/weather.module').then(m => m.WeatherModule)
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'logout',
    redirectTo: 'action/logout'
  },
  {
    path: 'callback',
    redirectTo: 'action/callback'
  },
  {
    path: 'resendVerification',
    redirectTo: 'action/resend',
    pathMatch: 'full'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'projects'
  },
  {
    path: '**',
    redirectTo: 'action/not-found'
  }
];

@NgModule({
  imports: [
    HeaderModule,
    BreadcrumbModule,
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
